<template>
  <v-list-item-avatar class="social-network">
    <v-icon class="white--text" :class="avatar.icon.class">{{
      avatar.icon.value
    }}</v-icon>
  </v-list-item-avatar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({ avatars: "conversation/avatar" }),
    avatar() {
      if (this.avatars[this.type]) {
        return this.avatars[this.type];
      } else {
        return this.avatars["default"];
      }
    },
  },
};
</script>