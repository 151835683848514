<template>
  <div class="contact-info-form">
    <div class="pt-3">
      <v-text-field
        :value="identifier"
        :placeholder="$t('chat.fields.contactInfoId.placeholder')"
        :label="$t('chat.fields.contactInfoId.title')"
        append-icon="mdi-account-search-outline"
        class="font-weight-bold mb-3"
        autocomplete="off"
        hide-details
        @click:append="
          $permission.$can('contact-info.get', ['user', 'service'])
            ? checkContactInfo()
            : () => {}
        "
        @input="updateIdentifier"
        :loading="loading.checkContact"
      ></v-text-field>
      <div class="mb-8 grey--text text--darken-1">
        {{ $t("chat.fields.contactInfoId.help") }}
      </div>
      <template v-if="displayContactInfoForm">
        <div v-if="!loading.checkContact">
          <v-form ref="contactInfoForm" @submit.prevent="save()">
            <v-select
              :label="$t('contacts.fields.gender.title')"
              :placeholder="$t('contacts.fields.gender.placeholder')"
              :items="genderItems"
              v-model="form.gender"
              append-icon="mdi-human-male-female"
              class="font-weight-bold"
              @input="delayTouch($v.form.gender)"
              @blur="$v.form.gender.$touch"
              :error-messages="genderErrors"
            ></v-select>
            <v-text-field
              v-model="form.firstName"
              :label="$t('contacts.fields.firstName.title')"
              :placeholder="$t('contacts.fields.firstName.placeholder')"
              @input="delayTouch($v.form.firstName)"
              @blur="$v.form.firstName.$touch"
              :error-messages="firstNameErrors"
              append-icon="mdi-text-short"
              class="font-weight-bold"
            ></v-text-field>
            <v-text-field
              v-model="form.lastName"
              :label="$t('contacts.fields.lastName.title')"
              :placeholder="$t('contacts.fields.lastName.placeholder')"
              @input="delayTouch($v.form.lastName)"
              @blur="$v.form.lastName.$touch"
              :error-messages="lastNameErrors"
              append-icon="mdi-text-short"
              class="font-weight-bold"
            ></v-text-field>
            <template v-for="(email, index) in form.email">
              <div class="d-flex align-center" :key="`email-${index}`">
                <v-text-field
                  v-model="form.email[index]"
                  :label="`${$t('contacts.fields.email.title')} ${$utils.pad(
                    index + 1,
                    2
                  )}`"
                  :placeholder="`${$t(
                    'contacts.fields.email.placeholder'
                  )} ${$utils.pad(index + 1, 2)}`"
                  @input="delayTouch($v.form.email[index])"
                  @blur="$v.form.email[index].$touch"
                  :error-messages="emailErrors[index]"
                  :prepend-icon="index === 0 ? 'mdi-at' : ''"
                  :class="{ 'ml-8': index !== 0 }"
                  class="font-weight-bold"
                ></v-text-field>
                <v-btn
                  @click="removeFormField('email', index)"
                  icon
                  v-if="index !== 0"
                >
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="addFormField('email')"
                  icon
                  v-if="index + 1 === form.email.length"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-for="(phone, index) in form.phone">
              <div class="d-flex align-center" :key="`phone-${index}`">
                <v-text-field
                  v-model="form.phone[index]"
                  :label="`${$t('contacts.fields.phone.title')} ${$utils.pad(
                    index + 1,
                    2
                  )}`"
                  :placeholder="`${$t(
                    'contacts.fields.phone.placeholder'
                  )} ${$utils.pad(index + 1, 2)}`"
                  @input="delayTouch($v.form.phone[index])"
                  @blur="$v.form.phone[index].$touch"
                  :error-messages="phoneErrors[index]"
                  :prepend-icon="index === 0 ? 'mdi-phone-outline' : ''"
                  :class="{ 'ml-8': index !== 0 }"
                  class="font-weight-bold"
                ></v-text-field>
                <v-btn
                  @click="removeFormField('phone', index)"
                  icon
                  v-if="index !== 0"
                >
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="addFormField('phone')"
                  icon
                  v-if="index + 1 === form.phone.length"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-for="(address, index) in form.address">
              <div class="d-flex align-center" :key="`address-${index}`">
                <v-text-field
                  v-model="form.address[index]"
                  :label="`${$t('contacts.fields.address.title')} ${$utils.pad(
                    index + 1,
                    2
                  )}`"
                  :placeholder="`${$t(
                    'contacts.fields.address.placeholder'
                  )} ${$utils.pad(index + 1, 2)}`"
                  @input="delayTouch($v.form.address[index])"
                  @blur="$v.form.address[index].$touch"
                  :error-messages="addressErrors[index]"
                  :prepend-icon="index === 0 ? 'mdi-map-marker-outline' : ''"
                  :class="{ 'ml-8': index !== 0 }"
                  class="font-weight-bold"
                ></v-text-field>
                <v-btn
                  @click="removeFormField('address', index)"
                  icon
                  v-if="index !== 0"
                >
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="addFormField('address')"
                  icon
                  v-if="index + 1 === form.address.length"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <div
              v-if="
                $permission.$can('contact-info.update', ['user', 'service'])
              "
              class="pt-2 text-right"
            >
              <v-btn
                :loading="loading.saveContactInfo"
                :disabled="!identifier || $v.form.$invalid"
                color="primary"
                type="submit"
              >
                <span>{{ $i18n.t("btn.save") }}</span>
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-else class="text-center">
          <v-progress-circular
            indeterminate
            color="secondary"
            width="4"
            size="40"
          ></v-progress-circular>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.contact-info-form {
  .v-icon {
    font-size: 18px !important;
  }
}
</style>

<script>
import Vue from "vue";
import { helpers, maxLength, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";

const contactInfoFormDefault = {
  gender: null,
  firstName: null,
  lastName: null,
  email: [null],
  address: [null],
  phone: [null],
};

export default {
  mixins: [FormDelayTouchMixin],
  data: () => ({
    form: Vue._.cloneDeep(contactInfoFormDefault),
    items: {
      gender: [],
    },
    loading: {
      checkContact: false,
      saveContactInfo: false,
    },
    displayContactInfoForm: false,
  }),
  props: {
    contactInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    identifier: {
      type: String,
    },
    serviceId: {
      type: Number,
    },
    reloadId: {
      type: Number,
    },
  },
  sockets: {
    "contacts-infos/update"(data) {
      this.loading.saveContactInfo = false;
      if (data.status === "success") {
        this.notify({ message: this.$t("contacts.update.success") });
        this.$emit("updateContactInfo", data.contactInfo);

        this.$emit("contactInfoSaved", {
          contactInfo: data.contactInfo,
          update: true,
        });
      } else if (data.status === "error") {
        const code = data.code;
        let message;
        if (code === "E403") {
          message = "error_codes.403";
        } else if (code === "E404") {
          message = "contacts.errors.E404";
        } else {
          message = "error_occured";
        }
        this.notify({ message: this.$i18n.t(message) });
      }
    },
    "contacts-infos/updated"(data) {
      if (this.contactInfo.id === data.contactInfo.id) {
        this.$emit("updateContactInfo", data.contactInfo);
        this.setForm(data.contactInfo);
      }
    },
    "contacts-infos/create"(data) {
      this.loading.saveContactInfo = false;
      if (data.status === "success") {
        this.notify({ message: this.$t("contacts.create.success") });

        this.$emit("updateContactInfo", data.contactInfo);
        this.$emit("contactInfoSaved", {
          contactInfo: data.contactInfo,
          update: false,
        });
      } else if (data.status === "error") {
        const code = data.code;
        let message;
        if (code === "E403") {
          message = "error_codes.403";
        } else {
          message = "error_occured";
        }
        this.notify({ message: this.$i18n.t(message) });
      }
    },
  },
  validations() {
    const validations = {
      form: {
        gender: {
          matchGender: (val) =>
            !helpers.req(val) || /^(male|female)$/.test(val),
        },
        firstName: {
          maxLength: maxLength(100),
        },
        lastName: {
          maxLength: maxLength(100),
        },
        email: {},
        phone: {},
        address: {},
      },
    };
    for (const prop in this.form.email) {
      validations.form.email[prop] = { email };
    }
    for (const prop in this.form.phone) {
      validations.form.phone[prop] = {
        matchPhone: (val) =>
          !helpers.req(val) || /^(\+)?[0-9]{1,30}$/.test(val),
      };
    }
    for (const prop in this.form.address) {
      validations.form.address[prop] = {
        maxLength: maxLength(100),
      };
    }
    return validations;
  },
  methods: {
    updateIdentifier(val) {
      this.displayContactInfoForm = false;
      this.$emit("update:identifier", val);
    },
    async checkContactInfo() {
      // reset form
      this.resetForm();
      this.displayContactInfoForm = false;

      if (!this.identifier) return;

      this.loading.checkContact = true;
      this.displayContactInfoForm = true;

      try {
        const response = await this.request({
          url: `contacts-infos.list?filter=service.id||$eq||${this.serviceId}&filter=identifier||$eq||${this.identifier}&limit=1`,
          messages: {
            403: true,
            500: true,
          },
        });
        const data = response.data.data;
        // update contact info
        const contactInfo = data[0] || {};
        this.$emit("updateContactInfo", contactInfo);
        // set form
        this.setForm(contactInfo);

        this.displayContactInfoForm = true;
      } catch (error) {
        // empty
        this.displayContactInfoForm = false;
      }
      this.loading.checkContact = false;
      return;
    },
    setForm(contactInfo) {
      this.form = this._.cloneDeep(contactInfoFormDefault);
      for (const prop in this.form) {
        if (contactInfo[prop]) {
          if (Array.isArray(contactInfo[prop])) {
            if (contactInfo[prop].length > 0) {
              this.form[prop] = contactInfo[prop];
            }
          } else {
            this.form[prop] = contactInfo[prop];
          }
        }
      }
    },
    resetForm() {
      this.form = this._.cloneDeep(contactInfoFormDefault);
      // reset form
      if (this.$refs.contactInfoForm) {
        this.$refs.contactInfoForm.reset();
      }
      // reset validation
      this.$v.form.$reset();
    },
    save() {
      let event;
      let data;
      this.notify({ status: false });

      if (!this.identifier) return;

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      if (this.contactInfo.id) {
        event = "contacts-infos/update";
        data = {
          ...this.getFormData(),
          contactInfoId: this.contactInfo.id,
        };
      } else {
        event = "contacts-infos/create";
        data = {
          ...this.getFormData(),
          serviceId: this.serviceId,
          identifier: this.identifier,
        };
      }

      this.loading.saveContactInfo = true;
      this.$socket.emit(event, data);
    },
    getFormData() {
      const data = {};
      for (const prop in this.form) {
        if (Array.isArray(this.form[prop])) {
          data[prop] = [
            ...new Set(this.form[prop].filter((val) => (val ? true : false))),
          ];
        } else {
          data[prop] = this.form[prop];
        }
      }
      return data;
    },
    addFormField(field) {
      this.form[field].push(null);
    },
    removeFormField(field, index) {
      this.form[field].splice(index, 1);
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    reloadId: {
      async handler() {
        await this.checkContactInfo();
      },
      immediate: true,
    },
  },
  computed: {
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      !this.$v.form.gender.matchGender &&
        errors.push(this.$t("contacts.fields.gender.invalid"));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;

      !this.$v.form.firstName.maxLength &&
        errors.push(
          this.$t("contacts.fields.firstName.maxLength", {
            max: this.$v.form.firstName.$params.maxLength.max,
          })
        );
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;

      !this.$v.form.lastName.maxLength &&
        errors.push(
          this.$t("contacts.fields.lastName.maxLength", {
            max: this.$v.form.lastName.$params.maxLength.max,
          })
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      for (const prop in this.form.email) {
        errors[prop] = [];
        if (!this.$v.form.email[prop].$dirty) continue;
        !this.$v.form.email[prop].email &&
          errors[prop].push(this.$t("contacts.fields.email.invalid"));
      }
      return errors;
    },
    phoneErrors() {
      const errors = [];
      for (const prop in this.form.phone) {
        errors[prop] = [];
        if (!this.$v.form.phone[prop].$dirty) continue;
        !this.$v.form.phone[prop].matchPhone &&
          errors[prop].push(this.$t("contacts.fields.phone.invalid"));
      }
      return errors;
    },
    addressErrors() {
      const errors = [];
      for (const prop in this.form.address) {
        errors[prop] = [];
        if (!this.$v.form.address[prop].$dirty) continue;
        !this.$v.form.address[prop].maxLength &&
          errors[prop].push(
            this.$t("contacts.fields.address.maxLength", {
              max: this.$v.form.address[prop].$params.maxLength.max,
            })
          );
      }
      return errors;
    },
    ...mapGetters({ genderItems: "contact/genders" }),
  },
};
</script>